
.order-lists-page
  width: 100%
  min-height: calc(100dvh - (62px + 50px));
  margin-top: $header-height-mobile
  @media screen and (min-width: 1024px){
    margin-top: $header-height
  }

.order-empty
    min-height: calc(100dvh - 260px)
    display: flex;
    align-items: center
    justify-content: center

.order-list-item
    border: 1px solid $color-bnn-gray-light
    border-radius: 8px

.order-list-item-header
    padding: 12px
    border-bottom: 1px solid $color-bnn-gray-light

.order-list-item-body,
.order-list-item-footer
    padding: 0px 12px

.text-gray-medium-2
    color: $color-bnn-gray-medium-2

.product-item
    display: flex
    gap: 12px

.order-number
    font-weight: bold
    text-transform: uppercase

.order-status
    padding: 2px 4px
    border-radius: 4px
    font-size: 12px
    margin-left: 4px
    background: $color-bnn-gray-medium-2
    color: white

    &.complete
        background: green

    &.process
        background: $color-bnn

    &.create
        background: $color-bnn-gray-light-2
        color: $color-bnn-gray-medium

    &.cancel
        background: $color-bnn-red

.text-color-gray-light
  color: $color-bnn-gray-light
